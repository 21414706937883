<template>
  <div id="page-edit">
    <div class="xs-p-20 xs-fill-white">
      <form @submit="checkForm" action="" method="post" novalidate="true">
        <div class="form-section first-section">
          <!--FIRSTNAME LASTNAME-->
          <div v-if="$session.get('lang') ===$const.LANG_ES">
            <div class="row">
              <div class="col-md-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="LastName">{{$t('last_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.lastName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.lastName }}</span><i class="icon-error"></i>
                </span>
                  </div>
                  <input id="LastName" ref="lastName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.lastName , 'has-success': errors.lastName === false}"
                         name="lastName"
                         v-model="lastName"
                         v-bind:placeholder="$t('place_holder_last_name')"
                         type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="FirstName">{{$t('first_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.firstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.firstName }}</span><i class="icon-error"></i>
                </span>
                  </div>
                  <input id="FirstName" ref="firstName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.firstName , 'has-success': errors.firstName === false}"
                         name="firstName" v-model="firstName"
                         v-bind:placeholder="$t('place_holder_first_name')"
                         type="text">
                </div>
              </div>

            </div>
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="localLastName">{{$t('local_last_name')}}<span
                      class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.localLastName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.localLastName }}</span><i class="icon-error"></i>
              </span>
                  </div>
                  <input id="localLastName" ref="localLastName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.localLastName , 'has-success': errors.localLastName === false}"
                         name="localLastName" v-model="localLastName" value=""
                         v-bind:placeholder="$t('place_holder_local_last_name')" type="text">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-field">
                  <div class="xs-flex xs-items-center xs-mb-5">
                    <label class="label label-input xs-mb-0" for="localFirstName">{{$t('local_first_name')}}<span
                      class="cl-red">{{ $t('required') }}</span></label>
                    <span v-if="errors.localFirstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.localFirstName }}</span><i class="icon-error"></i>
              </span>
                  </div>
                  <input id="localFirstName" ref="localFirstName" class="input xs-mb-10  "
                         v-bind:class="{ 'has-errors': errors.localFirstName , 'has-success': errors.localFirstName === false}"
                         name="localFirstName" v-model="localFirstName" value=""
                         v-bind:placeholder="$t('place_holder_local_first_name')" type="text">
                </div>
              </div>
            </div> -->
          </div>
          <div v-else>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0" for="FirstName">{{$t('first_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.firstName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.firstName }}</span><i class="icon-error"></i>
                </span>
              </div>
              <input id="FirstName" ref="firstName" class="input xs-mb-10  "
                     v-bind:class="{ 'has-errors': errors.firstName , 'has-success': errors.firstName === false}"
                     name="firstName" v-model="firstName" value="" v-bind:placeholder="$t('place_holder_first_name')"
                     type="text">
            </div>
            <div class="form-field">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0" for="LastName">{{$t('last_name')}}<span class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.lastName" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.lastName }}</span><i class="icon-error"></i>
                </span>
              </div>
              <input id="LastName" ref="lastName" class="input xs-mb-10  "
                     v-bind:class="{ 'has-errors': errors.lastName , 'has-success': errors.lastName === false}"
                     name="lastName" v-model="lastName" value="" v-bind:placeholder="$t('place_holder_last_name')"
                     type="text">
            </div>
          </div>

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('email')}}<span
                class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.emailAddress" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.emailAddress }}</span><i class="icon-error"></i>
              </span>
            </div>
            <div class="email-list">
              <div v-for="(email, index) in emailList"
                   class="form-field">
                <input v-model.trim="emailList[index]"
                       :key="index"
                       type="text"
                       class="input xs-mb-10">
                <a v-if="index > 0"
                   v-on:click.stop="remove(email)"
                   class="remove">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <!--<input id="emailAddress" ref="emailAddress"
                   v-bind:class="{ 'has-errors': errors.emailAddress , 'has-success': errors.emailAddress === false }"
                   name="emailAddress" v-model="emailAddress" class="input xs-mb-10" value="" type="text">-->
            <!--v-bind:placeholder="$t('place_holder_email')"-->
          </div>

          <!-- <div class="form-group">
            <label for="gender">{{$t('gender')}}</label>
            <select class="form-control" id="gender" v-model="gender">
              <option value="">{{$t('not_specify')}}</option>
              <option value="M">{{$t('male')}}</option>
              <option value="F">{{$t('female')}}</option>
            </select>
          </div> -->

          <!--<div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('email')}}: </label> <label class="ml-10"> {{ emailAddress}}</label>
            </div>
          </div>-->

          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="phone">{{$t('phone_number_register')}}<span class="cl-red">{{ $t('required') }}</span></label>
              <span v-if="errors.phone" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                          <span>{{ errors.phone }}</span><i class="icon-error"></i>
                </span>
            </div>
            <input id="phone" ref="phone" class="input xs-mb-10 " :minlength=10 :maxlength=12
                   v-bind:class="{ 'has-errors': errors.phone, 'has-success': !errors.phone }" name="phone"
                   v-model="phone" value="" type="text">
            <!--v-bind:placeholder="$t('place_holder_phone')"-->
          </div>
          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('profile.register_news')}}</label>
            </div>
            <label class="xs-mb-30 xs-block">
              <input v-model="receive_notification" class="checkbox" type="checkbox">
              <span>{{$t('profile.register_news_content')}}</span>
            </label>
          </div>

          <div v-if="false" class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0">{{$t('profile.register_html')}}</label>
            </div>
            <label class="xs-mb-30 xs-block">
              <input v-model="receive_html_mail" class="checkbox" type="checkbox">
              <span>{{$t('profile.register_html_content')}}</span>
            </label>
          </div>

          <div class="birthday-section" v-if="$session.get('lang') ===$const.LANG_ES">
            <div class="form-field ">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0">{{$t('birth_day')}}<span
                  class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.birthday" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.birthday }}</span><i class="icon-error"></i>
              </span>
              </div>
              <div class="row pb-20">
                <div class="col-xs-4">
                  <select v-model="birthday.year" class="form-control " ref="birthdayYear"
                          :disabled="(user && user.birthdate && user.birthdate.year) ? true : false">
                    <option value="">{{$t('year')}}</option>
                    <option v-for="item in yearList"
                            v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-4">
                  <select v-model="birthday.month" class="form-control " ref="birthdayMonth"
                          :disabled="(user && user.birthdate && user.birthdate.month) ? true : false">
                    <option value="">{{$t('month')}}</option>
                    <option v-for="item in 12" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-4">
                  <select v-model="birthday.day" class="form-control " ref="birthdayDay"
                          :disabled="(user && user.birthdate && user.birthdate.day) ? true : false">
                    <option value="">{{$t('day')}}</option>
                    <option v-for="item in 31" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="birthday-section" v-else>
            <div class="form-field ">
              <div class="xs-flex xs-items-center xs-mb-5">
                <label class="label label-input xs-mb-0">{{$t('birth_day')}}<span
                  class="cl-red">{{ $t('required') }}</span></label>
                <span v-if="errors.birthday" class="xs-text-right typo-l7 xs-text-red xs-ml-auto error-text">
                                        <span>{{ errors.birthday }}</span><i class="icon-error"></i>
              </span>
              </div>
              <div class="row pb-20">
                <div class="col-xs-4">
                  <select v-model="birthday.day"
                          class="form-control "
                          ref="birthdayDay"
                          :disabled="(user && user.birthdate && user.birthdate.day) ? true : false">
                    <option value="">{{$t('day')}}</option>
                    <option v-for="item in 31" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-4">
                  <select v-model="birthday.month"
                          class="form-control "
                          ref="birthdayMonth"
                          :disabled="(user && user.birthdate && user.birthdate.month) ? true : false">
                    <option value="">{{$t('month')}}</option>
                    <option v-for="item in 12" v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-4">
                  <select v-model="birthday.year" class="form-control " ref="birthdayYear"
                          :disabled="(user && user.birthdate && user.birthdate.year) ? true : false">
                    <option value="">{{$t('year')}}</option>
                    <option v-for="item in yearList"
                            v-bind:value="item">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="false" class="xs-flex xs-items-center">
            <span><img src="https://static.phdvasia.com/mx/icons/ph3_bnr-ponta.png" style="max-width: 100px"/></span>
            <div v-if="hasPonta" class="flex-1">
              <div class="pl-20">
                <div class="row">
                  <label class="col-xs-3">{{$t('ponta.pontaid')}}</label>
                  <label class="box-input-text col-xs-3">{{ponta.id}} </label>
                </div>
                <div class="row">
                  <label class="col-xs-3">{{$t('ponta.ponta_point')}}</label>
                  <label class="box-input-text col-xs-3">{{ponta.point}} {{$t('ponta.point')}} </label>
                </div>
                <div class="row">
                  <p class="col-xs-offset-3">{{$t('ponta.thankful')}}</p>
                  <p class="col-xs-offset-3">{{$t('ponta.point_notice',{point: mapPoint(ponta.times)})}} <span
                    class="cl-red" v-if="ponta.times > 1">({{ $t('ponta.point_notice_ex', {point: mapPoint(ponta.times)})}})</span>
                  </p>
                </div>
              </div>
            </div>
            <span v-if="!hasPonta" class="pl-5 pr-5">{{$t('edit_profile.not_exist_ponta')}}</span>
            <span v-if="!hasPonta" class="xs-ml-auto"><input type="button" class="button button--primary"
                                                             style="min-height: 40px"
                                                             :value="$t('edit_profile.button_register')"
                                                             @click="$router.push({name: 'ponta-attach'})"></span>
          </div>
          <!--<div class="clearfix pb-20"></div>-->
          <input type="submit" class="button button--primary" v-bind:value="$t('btn_edit')">
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
    moment
  },
  mounted: function () {
    this.user = this.lodash.cloneDeep(this.$auth.user())

    let birthdate = null
    if (this.user.birthdate) {
      birthdate = moment(this.user.birthdate, 'YYYY/MM/DD')
      if (!birthdate.isValid()) {
        birthdate = null
      }
    }

    this.user.birthdate = {
      year: '',
      month: '',
      day: ''
    }
    this.receive_html_mail = this.user.receive_html_mail || 0
    this.receive_notification = this.user.receive_notification || 0
    this.emailAddress = this.user.email
    this.phone = this.user.phone
    this.firstName = this.$sanitize(this.user.first_name)
    this.lastName = this.$sanitize(this.user.last_name)
    this.localFirstName = this.user.local_first_name
    this.localLastName = this.user.local_last_name

    if (birthdate) {
      this.birthday.day = this.user.birthdate.day = birthdate.format('D')
      this.birthday.month = this.user.birthdate.month = birthdate.format('M')
      this.birthday.year = this.user.birthdate.year = birthdate.format('Y')
    }

    this.gender = this.user.gender

    for (let i in this.user.emails) {
      this.emailList.push(this.user.emails[i].email)
    }

    this.eventTrackingScreenLoad(this, {screen: {'name': 'user.edit-profile', 'type': 'user'}})
    let formInfo = {
      id: 'edit-profile-form',
      name: 'edit-profile-form',
      category: 'edit-profile',
      url: window.location.href
    }

    this.eventTrackingFormLoad(formInfo)
  },
  watch: {
    /* emailList: {
      handler: function (newValue, oldValue) {
        let vm = this
        for(let i in newValue){
          if (!vm.validEmail(vm.emailList[i])) {
            vm.emailList[i].error = vm.$t('validate_invalid_email')
          }else{
            vm.emailList[i].error = null
          }
        }
      },
      deep: true
    }, */
    firstName: function (newValue, oldValue) {
      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')
      } else {
        this.errors.firstName = false
      }

      if (this.$session.get('lang') === this.$const.LANG_EN) {
        this.localFirstName = newValue
      }
    },
    lastName: function (newValue, oldValue) {
      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
      } else {
        this.errors.lastName = false
      }

      if (this.$session.get('lang') === this.$const.LANG_EN) {
        this.localLastName = newValue
      }
    },
    emailAddress: function (newValue, oldValue) {
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
      } else {
        this.errors.emailAddress = false
      }
    },
    localFirstName: function (newValue, oldValue) {
      if (this.$session.get('lang') === this.$const.LANG_ES) {
        if (!this.localFirstName) {
          this.errors.localFirstName = this.$t('required_display')
        } else {
          this.errors.localFirstName = false
        }
      }
    },
    localLastName: function (newValue, oldValue) {
      if (this.$session.get('lang') === this.$const.LANG_ES) {
        if (!this.localLastName) {
          this.errors.localLastName = this.$t('required_display')
        } else {
          this.errors.localLastName = false
        }
      }
    },
    phone: function (newValue, oldValue) {
      var numbers = /^[-+]?[0-9]+$/
      var message = ''
      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'phone', oldValue)
        return
      } else if (!this.phone) {
        message = this.$t('required_display')
      } else if (this.phone.length < this.$const.PHONE_MIN_LENGTH || this.phone.length > this.$const.PHONE_MAX_LENGTH) {
        message = this.$t('validate_invalid_phone')
      } else {
        message = null
      }

      this.errors.phone = message
    }
  },
  computed: {
    yearList () {
      var year = new Date().getFullYear()
      var list = []
      for (var i = year; i > this.$const.LIMIT_BIRTHDAY; i--) {
        list.push(i)
      }

      return list
    }
  },
  data () {
    return {
      user: {},
      selected: '',
      birthday: {
        year: '',
        month: '',
        day: ''
      },
      firstName: '',
      lastName: '',
      localFirstName: '',
      localLastName: '',
      phone: '',
      emailAddress: '',
      emailList: [],
      errors: {
        'birthday': '',
        'emailAddress': '',
        'phone': '',
        'firstName': ''
      },
      gender: '',
      receive_notification: 0,
      receive_html_mail: 0,
      hasPonta: false,
      ponta: {
        id: '**********1234',
        point: 0,
        times: 0
      }
    }
  },
  methods: {
    remove (email) {
      this.emailList = this.emailList.filter(function (value) {
        return value != email
      })
    },
    convertKana (text, type) {
      let vm = this
      type = vm.lodash.toLower(type)

      this.convertToKanaName(text, function (err, res) {
        if (type === 'lastname') {
          vm.localLastName = res
        } else if (type === 'firstname') {
          vm.localFirstName = res
        }
      })
    },
    mapPoint (times) {
      if (times >= 5) {
        return 5
      } else if (times >= 2) {
        return 2
      }
      return 1
    },
    checkForm: function (e) {
      e.preventDefault()

      let flag = false

      this.errors = {
        'birthday': '',
        'emailAddress': '',
        'phone': '',
        'firstName': '',
        'lastName': '',
        'localFirstName': '',
        'localLastName': ''
      }

      if (!this.firstName) {
        this.errors.firstName = this.$t('required_display')
        this.$refs.firstName.focus()
        flag = true
      } else {
        this.errors.firstName = false
      }

      if (!this.lastName) {
        this.errors.lastName = this.$t('required_display')
        if (!flag) {
          this.$refs.lastName.focus()
        }
        flag = true
      } else {
        this.errors.lastName = false
      }

      // if (this.$session.get('lang') === this.$const.LANG_ES) {
      //   if (!this.localFirstName) {
      //     this.errors.localFirstName = this.$t('required_display')
      //     if (!flag) {
      //       this.$refs.localFirstName.focus()
      //     }
      //     flag = true
      //   } else {
      //     this.errors.localFirstName = false
      //   }

      //   if (!this.localLastName) {
      //     this.errors.localLastName = this.$t('required_display')
      //     if (!flag) {
      //       this.$refs.localLastName.focus()
      //     }
      //     flag = true
      //   } else {
      //     this.errors.localLastName = false
      //   }
      // }

      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
        if (!flag) {
          this.$refs.emailAddress.focus()
        }
        flag = true
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
        if (!flag) {
          this.$refs.emailAddress.focus()
        }
        flag = true
      } else {
        this.errors.emailAddress = false
      }

      if (!this.phone) {
        this.errors.phone = this.$t('required_display')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else if (this.phone.length < this.$const.PHONE_MIN_LENGTH || this.phone.length > this.$const.PHONE_MAX_LENGTH) {
        this.errors.phone = this.$t('validate_invalid_phone')
        if (!flag) {
          this.$refs.phone.focus()
        }
        flag = true
      } else {
        this.errors.phone = false
      }

      if (this.birthday.day === '' || this.birthday.month == '' || this.birthday.year == '') {
        this.errors.birthday = this.$t('required_display')
        if (!flag) {
          if (this.birthday.day === '') {
            this.$refs.birthdayDay.focus()
          } else if (this.birthday.month === '') {
            this.$refs.birthdayMonth.focus()
          } else if (this.birthday.year === '') {
            this.$refs.birthdayYear.focus()
          }
        }
        flag = true
      } else if (!this.validBirthday(this.birthday)) {
        this.errors.birthday = this.$t('validate_invalid_birthday')
        if (!flag) {
          this.$refs.birthdayDay.focus()
        }
        flag = true
      }

      if (flag) {
        return false
      }

      this.updateProfile()
    },
    validEmail: function (email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validBirthday: function (day) {
      let d = new Date(day.year, day.month - 1, day.day)
      return d instanceof Date && !isNaN(d) && (d.getMonth() + 1) === parseInt(day.month)
    },
    updateProfile: function () {
      let user = JSON.parse(JSON.stringify(this.$auth.user()))
      let vm = this

      user.receive_notification = this.receive_notification ? 1 : 0
      user.receive_html_mail = this.receive_html_mail ? 1 : 0
      user.email = this.emailAddress
      user.first_name = this.firstName
      user.last_name = this.lastName
      user.local_first_name = this.localFirstName
      user.local_last_name = this.localLastName
      user.gender = this.gender
      user.phone = this.phone
      user.emails = this.lodash.cloneDeep(this.emailList)
      user.birthdate = moment(String(this.birthday.year + '/' + this.birthday.month + '/' + this.birthday.day)).format('YYYY/MM/DD')
      this.eventTrackingFormSubmit({id: 'edit-profile-form'})

      this.axios.apiCustomer.updateProfile(user, (response) => {
        let dataResponse = response.data.data.item
        let info = vm.$auth.user()
        if (info.emails) {
          vm.emailList = []
          for (let i in user.emails) {
            vm.emailList.push(user.emails[i].email)
          }
        }

        user.email = dataResponse.email
        user.emails = dataResponse.emails
        vm.$auth.token(btoa(escape(JSON.stringify(user))))
        vm.$auth.user(user)

        this.eventTrackingFormSuccess({id: 'edit-profile-form'})
        vm.redirectTo('profile')
        vm.$notify({
          type: 'success',
          title: vm.$t('noti_title_update_success')
        })
      }, (error) => {
        error = error.error
        let formInfo = {
          id: 'register-form',
          'error': {
            'code': error.code,
            'type': 'validation',
            'error': error.message
          }
        }
        vm.eventTrackingFormError(formInfo)
      })
    }
  }
}

</script>
<style scoped>
  .box-input-text {
    border: 2px solid hsla(207, 8%, 51%, .5);
    border-radius: 4px;
    padding: 5px;
    min-width: 200px;
  }
</style>
